import React from 'react'
import SingleObjection, { ObjectionData } from "./SingleObjection";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ObjectionTypeComponentProps {
    objection_type: string
    instances: ObjectionData[];
}

const ObjectionTypeComponent : React.FC<ObjectionTypeComponentProps> = ({ objection_type, instances }) => {
    return(
        <div className="flex flex-col mb-2">
            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                >
                    {objection_type}
                </AccordionSummary>
                <AccordionDetails className="flex">
                    {
                        instances.map((instance, idx) => {
                            return(
                                <SingleObjection data={instance} key={idx} />
                            )
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ObjectionTypeComponent;